export default class Enums {  
  static get DOM_PATH_TEXT_TYPE() {
    return {
      textNode: 0,
      innerText: 1
    }
  }

  static get EVENT_TYPE() {
    return {
      custom: 0,
      session: 1,
      pageview: 2,
      click: 3,
      pageviewTitleCorrection: 4,
      visibilityChange: 5,
      focus: 6,
      blur: 7,
      orientationChange: 8,
      select: 9,
      change: 10,
      submit: 11,
      scroll: 12,
      resize: 13,
      snapshot: 14,
      mouseMove: 15,
      mutation: 16,
      input: 17,
      pointerPress: 18,
      hover: 19,
      error: 20,
      identify: 21,
      touchMove: 22
    };
  }

  static get HOVER_EVENT_TYPE() {
    return {
      out: 0,
      in: 1
    };
  }

  // based on: https://javascript.info/mouse-events-basics
  static get POINTER_PRESS_EVENT_BUTTON() {
    return {
      primary: 0,
      auxiliary: 1,
      secondary: 2,
      back: 3,
      forward: 4,
    };
  }

  static get POINTER_PRESS_EVENT_TYPE() {
    return {
      down: 0,
      up: 1,
    };
  }

  static get MUTATION_TYPE() {
    return {
      removeNode: 0,
      addNode: 1,
      attribute: 2,
      text: 3,
    };
  }

  static get TRACKER_STORAGE_TYPE() {
    return {
      cookieAndLocalStorage: 0,
      cookieOnly: 1,
    };
  }

  static get VISIBILITY_CHANGE_STATE() {
    return {
      hidden: 0,
      visible: 1,
      pageHide: 2,
      prerender: 3,
    };
  }

  static isImportantEventType(type) {
    const importantTypes = [
      Enums.EVENT_TYPE.custom,
      Enums.EVENT_TYPE.session,
      Enums.EVENT_TYPE.pageview,
      Enums.EVENT_TYPE.click,
      Enums.EVENT_TYPE.change,
      Enums.EVENT_TYPE.submit,
      Enums.EVENT_TYPE.snapshot,
      Enums.EVENT_TYPE.error,
      Enums.EVENT_TYPE.identify
    ]

    return importantTypes.includes(type)
  }
}
