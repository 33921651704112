import Browser from "./browser";
import Builder from "./builder";
import Config from "./config";
import DOM from "./dom";
import Enums from "./enums";
import Queue from "./queue";
import Recorder from "./recorder";
import Sequence from "./sequence";
import Session from "./session";
import { Socket } from "phoenix";
import Utils from "../shared/utils";
import Visitor from "./visitor";

// DEFER: test
export default class Segmetric {
  constructor(config) {
    this.environmentId = config.environmentId;
    this.config = config;
    this.browser = new Browser(config.window ? config.window : window);

    this.visitor = new Visitor(this.browser);
    this.session = new Session(this.browser);
    this.nodeIdSequence = new Sequence();
    this.queue = new Queue(this.browser);

    this.pageviewEvent = null;
    this.socket = null;
    this.channel = null;
    this.intervalIds = [];

    this.inputBuffer = [];
    this.inputBufferSequence = null;

    this.lastMousePosition = [null, null, null];
    this.mousePositionBuffer = [];

    this.lastTouchPosition = [null, null, null, null];
    this.touchPositionBuffer = [];

    this.lastScrollPosition = [null, null, null];
    this.scrollPositionBuffer = [];

    this.lastClientSize = [null, null, null];
    this.clientSizeBuffer = [];

    this.handlePageviewTrigger();

    this.listenForErrorEvents();

    this.listenForPushStateEvents();
    this.listenForPopStateEvents();
    this.listenForTurbolinksLoadEvents();

    // DEFER: reenable
    // this.listenForReplaceStateEvents();

    this.listenForVisibilityChangeEvents();

    this.listenForClickEvents();
    this.listenForPointerPressEvents();

    this.listenForBlurEvents();
    this.listenForChangeEvents();
    this.listenForFocusEvents();
    this.listenForSelectEvents();
    this.listenForSubmitEvents();

    this.listenForHoverEvents();
    this.listenForInputEvents();
    this.listenForOrientationChangeEvents();
    this.listenForPointerMoveEvents();
    this.listenForResizeEvents();
    this.listenForScrollEvents();

    // FIXME: backgdoor for JavaScript unit tests
    if (!config.isUnitTest) {
      this.connect();
      this.heartbeat();

      setInterval(() => {
        this.heartbeat();
      }, Config.HEARTBEAT_INTERVAL);
    }
  }

  clearAllIntervals() {
    this.intervalIds.forEach((id) => this.clearInterval(id));
  }

  clearInterval(id) {
    clearInterval(id);
    this.intervalIds = this.intervalIds.filter((item) => item !== id);
  }

  connect() {
    this.socket = new Socket("/collector");
    this.socket.connect();

    let that = this;
    this.channel = this.socket.channel(Config.CHANNEL_TOPIC_PREFIX + this.visitor.getId());
    this.channel.join().receive("ok", (_response) => {
      that.queue.setChannel(that.channel);
      that.heartbeat();
    });
  }

  flushBuffers() {
    this.flushInputBuffer();
    this.flushMousePositionBuffer();
    this.flushTouchPositionBuffer();
    this.flushScrollPositionBuffer();
    this.flushClientSizeBuffer();
  }

  flushClientSizeBuffer() {
    if (this.clientSizeBuffer.length > 0) {
      this.track(Enums.EVENT_TYPE.resize, this.clientSizeBuffer);
      this.clientSizeBuffer = [];
    }
  }

  flushInputBuffer() {
    if (this.inputBuffer.length > 0) {
      this.track(Enums.EVENT_TYPE.input, this.inputBuffer);
      this.inputBuffer = [];
    }
  }

  flushMousePositionBuffer() {
    if (this.mousePositionBuffer.length > 0) {
      this.track(Enums.EVENT_TYPE.mouseMove, this.mousePositionBuffer);
      this.mousePositionBuffer = [];
    }
  }

  flushScrollPositionBuffer() {
    if (this.scrollPositionBuffer.length > 0) {
      this.track(Enums.EVENT_TYPE.scroll, this.scrollPositionBuffer);
      this.scrollPositionBuffer = [];
    }
  }

  flushTouchPositionBuffer() {
    if (this.touchPositionBuffer.length > 0) {
      this.track(Enums.EVENT_TYPE.touchMove, this.touchPositionBuffer);
      this.touchPositionBuffer = [];
    }
  }

  handlePageviewTrigger(force) {
    if (this.isNewPageview() || force) {
      this.clearAllIntervals();
      this.inputBufferSequence = new Sequence();
      this.flushBuffers();

      this.pageviewEvent = this.track(
        Enums.EVENT_TYPE.pageview,
        this.pageviewEvent
      );

      this.listenForTitleChange(this.pageviewEvent);
      this.listenForMutations();
    }
  }

  heartbeat() {
    this.flushBuffers();
    this.sendEventsToCollector();
  }

  // based on: https://stackoverflow.com/a/25673946
  historyHandler() {
    return (handlerType) => {
      let originalHandler = this.browser.history[handlerType];
      let that = this;

      return function () {
        let result = originalHandler.apply(this, arguments);
        let eventType = `segmetric:${handlerType.toLowerCase()}`;
        let event = new Event(eventType);
        event.arguments = arguments;
        that.browser.window.dispatchEvent(event);

        return result;
      };
    };
  }

  identify(identifier, metadata = null) {
    this.track(Enums.EVENT_TYPE.identify, identifier, metadata);
  }

  isDocumentNotVisible(browser, eventType, trigger) {
    if (eventType == Enums.EVENT_TYPE.visibilityChange) {
      if (trigger == "visibilityChange") {
        let state = browser.document.visibilityState;

        if (state == "visible" || state == "prerender") {
          return false;
        }
      }
    } else {
      return false;
    }

    return true;
  }

  isNewPageview() {
    return (
      this.pageviewEvent == null ||
      this.pageviewEvent.ur != this.browser.window.location.href
    );
  }

  isTextualInput(elem) {
    let tagName = elem.tagName;
    let type = elem.getAttribute("type");

    if (tagName == "INPUT" && (type == "checkbox" || type == "radio")) {
      return false;
    }

    if (tagName == "SELECT") {
      return false;
    }

    return true;
  }

  // actually listen to focusout event (because it bubbles), not blur event
  listenForBlurEvents() {
    this.browser.document.addEventListener("focusout", (event) => {
      this.track(Enums.EVENT_TYPE.blur, event);
    });
  }

  // The change event is fired for <input>, <select>, and <textarea> elements when an alteration to the element's value is committed by the user.
  // see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event
  listenForChangeEvents() {
    this.browser.document.addEventListener("change", (event) => {
      this.track(Enums.EVENT_TYPE.change, event);
    });
  }

  listenForClickEvents() {
    this.browser.document.addEventListener("click", (event) => {
      this.track(Enums.EVENT_TYPE.click, event);
    });
  }

  listenForErrorEvents() {
    this.browser.window.addEventListener("error", (event) => {
      this.track(Enums.EVENT_TYPE.error, event);
    });
  }

  // actually listen to focusin event (because it bubbles), not focus event
  listenForFocusEvents() {
    // elements receiving focus events: https://stackoverflow.com/a/1600194/13040586
    this.browser.document.addEventListener("focusin", (event) => {
      this.track(Enums.EVENT_TYPE.focus, event);
    });
  }

  listenForHoverEvents() {
    this.browser.document.addEventListener("pointerover", (event) => {
      this.track(Enums.EVENT_TYPE.hover, event, Enums.HOVER_EVENT_TYPE.in);
    });

    this.browser.document.addEventListener("pointerout", (event) => {
      this.track(Enums.EVENT_TYPE.hover, event, Enums.HOVER_EVENT_TYPE.out);
    });
  }  

  listenForInputEvents() {
    this.browser.document.addEventListener("input", (event) => {
      if (this.isTextualInput(event.target)) {
        this.writeToInputBuffer(event);
      }
    });
  }

  listenForMutations() {
    if (this.recorder) {
      this.recorder.disconnect();
    }

    if (!this.recorder) {
      this.browser.document.addEventListener("segmetric:mutation", (event) => {
        this.track(Enums.EVENT_TYPE.mutation, event.detail);
      });
    }

    this.recorder = new Recorder(this.browser, this.nodeIdSequence);

    this.track(Enums.EVENT_TYPE.snapshot);
  }

  listenForOrientationChangeEvents() {
    this.browser.window.addEventListener("orientationchange", (event) => {
      this.track(Enums.EVENT_TYPE.orientationChange, event);
    });
  }

  listenForPointerMoveEvents() {
    this.browser.window.addEventListener("pointermove", (event) => {
      if (event.pointerType === "mouse") {
        this.writeToMousePositionBuffer(event);
      } else {
        this.writeToTouchPositionBuffer(event)
      }
    });
  }

  listenForPointerPressEvents() {
    this.browser.document.addEventListener("pointerdown", (event) => {
      this.track(
        Enums.EVENT_TYPE.pointerPress,
        event,
        Enums.POINTER_PRESS_EVENT_TYPE.down
      );
    });

    this.browser.document.addEventListener("pointerup", (event) => {
      this.track(
        Enums.EVENT_TYPE.pointerPress,
        event,
        Enums.POINTER_PRESS_EVENT_TYPE.up
      );
    });
  }

  listenForPopStateEvents() {
    this.browser.window.addEventListener("popstate", (_event) => {
      this.handlePageviewTrigger();
    });
  }

  // based on: https://stackoverflow.com/a/25673946
  listenForPushStateEvents() {
    this.browser.history.pushState = this.historyHandler()("pushState");

    this.browser.window.addEventListener("segmetric:pushstate", (_event) => {
      if (
        !(this.browser.history.state && this.browser.history.state.turbolinks)
      ) {
        this.handlePageviewTrigger();
      }
    });
  }

  listenForResizeEvents() {
    this.browser.window.addEventListener("resize", (_event) => {
      this.writeToClientSizeBuffer();
    });
  }

  // select events can be dispatched only on form <input type="text"> and <textarea> elements
  // see: https://developer.mozilla.org/en-US/docs/Web/API/Element/select_event
  listenForSelectEvents() {
    this.browser.document.addEventListener("select", (event) => {
      this.track(Enums.EVENT_TYPE.select, event);
    });
  }

  listenForScrollEvents() {
    this.browser.window.addEventListener("scroll", (_event) => {
      this.writeToScrollPositionBuffer();
    });
  }

  listenForSubmitEvents() {
    this.browser.document.addEventListener("submit", (event) => {
      this.track(Enums.EVENT_TYPE.submit, event);
    });
  }

  // DEFER: use mutation observer instead of polling, see: https://stackoverflow.com/a/2499119/13040586
  listenForTitleChange(pageviewEvent) {
    let intervalId = setInterval(() => {
      if (
        this.pageviewEvent.id == pageviewEvent.id &&
        Utils.timestamp("millisecond") - pageviewEvent.ts <= 1000
      ) {
        if (this.browser.document.title != pageviewEvent.tl) {
          this.track(Enums.EVENT_TYPE.pageviewTitleCorrection, pageviewEvent);
          this.clearInterval(intervalId);
        }
      } else {
        this.clearInterval(intervalId);
      }
    }, 100);

    this.intervalIds.push(intervalId);
  }

  listenForTurbolinksLoadEvents() {
    this.browser.window.addEventListener("turbolinks:load", (_event) => {
      this.handlePageviewTrigger();
    });
  }

  // DEFER: reenable
  // based on: https://stackoverflow.com/a/25673946
  // listenForReplaceStateEvents() {
  //   this.browser.history.replaceState = this.historyHandler()("replaceState");

  //   this.browser.window.addEventListener("replacestate", (_event) => {
  //     this.handlePageviewTrigger();
  //   });
  // }

  // see: https://github.com/mdn/sprints/issues/3722
  // see: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
  // see: https://volument.com/blog/sendbeacon-is-broken#comments

  listenForVisibilityChangeEvents() {
    // see: (window vs document) https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
    this.browser.document.addEventListener("visibilitychange", (_event) => {
      this.track(Enums.EVENT_TYPE.visibilityChange, "visibilityChange");

      if (this.browser.document.visibilityState == "hidden") {
        this.flushBuffers();
        this.queue.sendBeacon(this.environmentId, this.visitor.getId());
      }
    });

    this.browser.window.addEventListener("pagehide", (_event) => {
      this.track(Enums.EVENT_TYPE.visibilityChange, "pageHide");
      this.flushBuffers();
      this.queue.sendBeacon(this.environmentId, this.visitor.getId());
    });
  }

  sendEventsToCollector() {
    this.queue.process(this.environmentId, this.visitor.getId());
  }

  track(type, ...params) {
    let browser = this.browser;
    let timestamp = Utils.timestamp("millisecond");

    if (
      this.session.isNew(timestamp) &&
      !this.isDocumentNotVisible(browser, type, params[0])
    ) {
      this.trackNewSession(timestamp);

      if (type != Enums.EVENT_TYPE.pageview) {
        this.handlePageviewTrigger(true);
      }
    }

    let event, pageviewId;
    let sessionId = this.session.getId();

    if (this.pageviewEvent) {
      pageviewId = this.pageviewEvent.id;
    }

    switch (type) {
      case Enums.EVENT_TYPE.blur:
        event = Builder.buildBlurEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.change:
        event = Builder.buildChangeEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.click:
        event = Builder.buildClickEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.error:
        event = Builder.buildErrorEvent(params[0], pageviewId, sessionId);
        break;        

      case Enums.EVENT_TYPE.focus:
        event = Builder.buildFocusEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.hover:
        event = Builder.buildHoverEvent(params[0], params[1], pageviewId, sessionId);
        break;
        
      case Enums.EVENT_TYPE.identify:
        event = Builder.buildIdentifyEvent(params[0], pageviewId, sessionId, params[1]);
        break;         

      case Enums.EVENT_TYPE.input:
        event = Builder.buildInputEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.mouseMove:
        event = Builder.buildMouseMoveEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.mutation:
        event = Builder.buildMutationEvent(
          browser,
          params[0],
          sessionId,
          pageviewId
        );
        break;

      case Enums.EVENT_TYPE.orientationChange:
        event = Builder.buildOrientationChangeEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.pageview:
        event = Builder.buildPageviewEvent(browser, sessionId, params[0]);
        break;

      case Enums.EVENT_TYPE.pageviewTitleCorrection:
        event = Builder.buildPageviewTitleCorrectionEvent(browser, params[0], sessionId);
        break;

      case Enums.EVENT_TYPE.pointerPress:
        event = Builder.buildPointerPressEvent(params[0], params[1], pageviewId, sessionId);
        break;        

      case Enums.EVENT_TYPE.resize:
        event = Builder.buildResizeEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.scroll:
        event = Builder.buildScrollEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.select:
        event = Builder.buildSelectEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.snapshot:
        event = Builder.buildSnapshotEvent(
          browser,
          this.recorder,
          sessionId,
          pageviewId,
          this.lastMousePosition
        );
        break;

      case Enums.EVENT_TYPE.submit:
        event = Builder.buildSubmitEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.touchMove:
        event = Builder.buildTouchMoveEvent(params[0], pageviewId, sessionId);
        break;

      case Enums.EVENT_TYPE.visibilityChange:
        event = Builder.buildVisibilityChangeEvent(
          browser,
          pageviewId,
          sessionId,
          params[0]
        );
        break;
    }

    this.queue.push(event);

    // DEFER: test
    if (Enums.isImportantEventType(type)) {
      this.flushBuffers()
      this.sendEventsToCollector()
    }

    this.visitor.regenerate();

    if (!this.isDocumentNotVisible(browser, type, params[0])) {
      this.session.regenerate(timestamp);
    }

    return event;
  }

  trackNewSession(timestamp) {
    this.session.init(timestamp);
    let expiredSessionId = this.pageviewEvent ? this.pageviewEvent.ss : null;

    let event = Builder.buildSessionEvent(
      this.browser,
      this.session.getId(),
      expiredSessionId
    );
    this.queue.push(event);
  }

  writeToBuffer(buffer, value1, value2, field) {
    let value = [
      Utils.timestamp("millisecond"),
      Math.round(value1),
      Math.round(value2),
    ];

    let last = this[field];
    let changed = value[1] != last[1] || value[2] != last[2];

    if (changed) {
      if (value[0] == last[0]) {
        buffer[buffer.length - 1] = value;
      } else {
        buffer.push(value);
      }

      this[field] = value;
    }
  }

  writeToClientSizeBuffer() {
    this.writeToBuffer(
      this.clientSizeBuffer,
      this.browser.document.documentElement.clientWidth,
      this.browser.document.documentElement.clientHeight,
      "lastClientSize"
    );
  }

  writeToInputBuffer(event) {
    let elem = event.target;

    if (elem.__segmetricOldValue__ == null) {
      let oldValue;

      if (DOM.isTextarea(elem)) {
        oldValue = elem.textContent;
      } else {
        oldValue = elem.getAttribute("value");
      }

      elem.__segmetricOldValue__ = oldValue ? oldValue : "";
    }

    let item = Builder.buildInputBufferItem(event, this.inputBufferSequence);
    this.inputBuffer.push(item);

    elem.__segmetricOldValue__ = elem.value;
  }

  writeToMousePositionBuffer(event) {
    this.writeToBuffer(
      this.mousePositionBuffer,
      event.pageX,
      event.pageY,
      "lastMousePosition"
    );
  }

  writeToScrollPositionBuffer() {
    this.writeToBuffer(
      this.scrollPositionBuffer,
      this.browser.window.scrollX,
      this.browser.window.scrollY,
      "lastScrollPosition"
    );
  }

  writeToTouchPositionBuffer(event) {
    let value = [
      event.pointerId,
      Utils.timestamp("millisecond"),
      Math.round(event.pageX),
      Math.round(event.pageY),
    ];

    // DEFER: last position may be for other pointer ID
    let last = this.lastTouchPosition;
    let changed = value[2] != last[2] || value[3] != last[3];

    if (changed) {
      if (value[1] == last[1]) {
        this.touchPositionBuffer[this.touchPositionBuffer.length - 1] = value;
      } else {
        this.touchPositionBuffer.push(value);
      }

      this.lastTouchPosition = value;
    }
  }
}
