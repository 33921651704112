import Config from "./config";
import Enums from "./enums";
import Utils from "../shared/utils";

export default class DOM {
  static buildDOMPathFromNode(node) {
    const nodes = [node, ...DOM.getNodeAncestors(node)]
    return DOM.buildDOMPathFromNodes(nodes)
  }

  static buildDOMPathFromNodes(nodes) {
    nodes = nodes.reverse()

    const path = nodes
      .reduce((acc, node) => {
        const vertex = DOM.buildDOMPathVertex(node)
        if (vertex) {
          acc.push(vertex);
        }
        return acc;
      }, [])

    const lastNode = nodes[nodes.length - 1]

    if (DOM.isElementNode(lastNode) && !DOM.isFormField(lastNode) && typeof lastNode.innerText !== "undefined") {
      const innerText = lastNode.innerText.trim()

      if (!Utils.isBlank(innerText)) {
        const vertex = ["text", Enums.DOM_PATH_TEXT_TYPE.innerText, innerText]
        path.push(vertex)
      }
    }

    return path
  }
  
  static buildDOMPathVertex(node) {
    switch (node.nodeType) {
      case Node.ELEMENT_NODE:
        return DOM.buildElementNodeDOMPathVertex(node)

      case Node.TEXT_NODE:
        return ["text", Enums.DOM_PATH_TEXT_TYPE.textNode, node.textContent]

      default:
        return null;
    }
  }

  static buildElementNodeDOMPathVertex(node) {
    const tag = DOM.getTag(node);
    const attrs = DOM.getAttributes(node, Config.IGNORED_ATTRIBUTES);

    return Utils.isBlank(attrs) ? tag : [tag, attrs];
  }

  static getAttributes(node, ignoredAttrs = []) {
    return Object.values(node.attributes)
      .filter((attr) => ignoredAttrs.indexOf(attr.nodeName) === -1)
      .reduce((acc, attr) => {
        acc[attr.nodeName] = attr.nodeValue;
        return acc;
      }, {});
  }

  static getNodeAncestors(node) {
    let ancestors = [];
    let parentNode = node.parentNode;

    while (parentNode != null) {
      ancestors.push(parentNode);
      parentNode = parentNode.parentNode;
    }

    return ancestors;
  }

  static getNodeByPath(path, document, childNodesKey = "childNodes") {
    return path.slice().reduce((acc, i) => acc[childNodesKey][i], document);
  }

  static getNodeType(node) {
    return node.nodeType ? node.nodeType : null;
  }

  static getTag(node) {
    return node.tagName ? node.tagName.toLowerCase() : null;
  }

  // DEFER: test
  static isCheckbox(elem) {
    return elem.tagName == "INPUT" && elem.getAttribute("type") == "checkbox";
  }

  static isElementNode(node) {
    return node.nodeType === Node.ELEMENT_NODE
  }

  static isFormField(elem) {
    return DOM.isInput(elem) || DOM.isSelect(elem) || DOM.isTextarea(elem)
  }

  // DEFER: test
  static isInput(elem) {
    return elem.tagName == "INPUT";
  }

  static isMirrored(node) {
    return node != null && node.__segmetricId__ != null;
  }

  static isPassword(elem) {
    return elem.tagName == "INPUT" && elem.getAttribute("type") == "password";
  }

  // DEFER: test
  static isRadio(elem) {
    return elem.tagName == "INPUT" && elem.getAttribute("type") == "radio";
  }

  // DEFER: test
  static isSelect(elem) {
    return elem.tagName == "SELECT";
  }

  static isTag(node, tag = null) {
    let actualTag = DOM.getTag(node);

    if (tag) {
      return actualTag === tag;
    }

    return actualTag ? true : false;
  }

  static isTextNode(node) {
    return node.nodeType === Node.TEXT_NODE
  }

  // DEFER: test
  static isTextarea(elem) {
    return elem.tagName == "TEXTAREA";
  }

  static unsetSubtreeIds(node) {
    node.__segmetricId__ = null;
    node.childNodes.forEach((childNode) => DOM.unsetSubtreeIds(childNode));
  }
}
