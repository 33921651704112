import UUID from "../shared/uuid";

export default class Queue {
  constructor(browser) {
    this.browser = browser;
    this.events = [];
    this.channel = null;
    this.isSending = false;
  }

  buildMessage(environmentId, visitorId) {
    return {
      ei: environmentId,
      id: UUID.generate(),
      pl: this.events,
      vr: visitorId,
    };
  }

  process(environmentId, visitorId) {
    if (this.channel == null) {
      return;
    }

    if (this.events.length > 0 && !this.isSending) {
      this.sendToChannel(environmentId, visitorId);
    }
  }

  push(event) {
    this.events.push(event);
  }

  removeEventsFromQueue(ids) {
    this.events = this.events.filter((event) => !ids.includes(event.id));
  }

  sendBeacon(environmentId, visitorId) {
    if (this.browser.features.beacon && this.events.length > 0) {
      let message = this.buildMessage(environmentId, visitorId);
      let data = new FormData();
      data.append("message", JSON.stringify(message));

      // DEFER: use config to load the uri
      this.browser.navigator.sendBeacon("/api/v1/collector/beacon", data);
    }
  }

  // DEFER: consider - how to test, maybe add backdoor for e2e tests
  sendToChannel(environmentId, visitorId) {
    this.isSending = true;

    let ids = this.events.map((event) => event.id);
    let message = this.buildMessage(environmentId, visitorId);

    this.channel
      .push("messages", message)
      .receive("ok", (_response) => {
        this.removeEventsFromQueue(ids);
        this.isSending = false;
      })
      .receive("error", (_response) => {
        this.isSending = false;
      })
      .receive("timeout", () => {
        this.isSending = false;
      });
  }

  setChannel(channel) {
    this.channel = channel;
  }
}
