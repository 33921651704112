import Config from "./config";
import Enums from "./enums";
import Tracker from "./tracker";

export default class Session {
  constructor(browser) {
    this.browser = browser;
    this.lastActivityTimestamp = null;

    this.tracker = new Tracker(
      browser,
      Config.SESSION_TRACKER_STORAGE_KEY,
      Enums.TRACKER_STORAGE_TYPE.cookieOnly,
      this.determineExpiry()
    );
  }

  // FIXME: backdoor for tests
  determineExpiry() {
    return this.browser.window.location.search !=
      "?segmetric_test_session_expiry"
      ? Config.SESSION_EXPIRY
      : Config.TEST_SESSION_EXPIRY;
  }

  getId() {
    return this.tracker.uuid;
  }

  init(timestamp) {
    if (this.isNotSet()) {
      this.initNotSet();
    } else if (this.isExpired(timestamp)) {
      this.initExpired(timestamp);
    }

    this.lastActivityTimestamp = timestamp;
  }

  initExpired() {
    this.tracker.reset();
  }

  initNotSet() {
    this.tracker.loadUUIDFromStorage();

    if (!this.tracker.uuid) {
      this.tracker.reset();
    }
  }

  isExpired(timestamp) {
    return !!(
      this.lastActivityTimestamp &&
      timestamp - this.lastActivityTimestamp > this.tracker.expiry
    );
  }

  isNew(timestamp) {
    return this.isNotSet() || this.isExpired(timestamp);
  }

  isNotSet() {
    return !this.getId();
  }

  regenerate(timestamp) {
    this.lastActivityTimestamp = timestamp;
    this.tracker.saveUUIDToStorage();
  }
}
