import Cookie from "./cookie";
import Enums from "./enums";
import LocalStorage from "./local_storage";
import UUID from "../shared/uuid";

export default class Tracker {
  constructor(browser, storageKey, storageType, expiry) {
    this.browser = browser;
    this.storageKey = storageKey;
    this.storageType = storageType;
    this.expiry = expiry;
    this.uuid = null;
  }

  deleteCookie() {
    if (this.browser.features.cookies) {
      Cookie.delete(this.browser, this.storageKey);
    }
  }

  deleteLocalStorage() {
    if (this.browser.features.localStorage) {
      LocalStorage.delete(this.browser, this.storageKey);
    }
  }

  static filterUUID(uuid) {
    return UUID.validate(uuid) ? uuid : null;
  }

  getFromCookie() {
    let uuid = this.browser.features.cookies
      ? Cookie.get(this.browser, this.storageKey)
      : null;

    return Tracker.filterUUID(uuid);
  }

  getFromLocalStorage() {
    let uuid = this.browser.features.localStorage
      ? LocalStorage.get(this.browser, this.storageKey)
      : null;

    return Tracker.filterUUID(uuid);
  }

  loadUUIDFromStorage() {
    let cookieTracker = this.getFromCookie();

    if (cookieTracker) {
      this.uuid = cookieTracker;
    } else if (this.storageType != Enums.TRACKER_STORAGE_TYPE.cookieOnly) {
      let localStorageTracker = this.getFromLocalStorage();

      if (localStorageTracker) {
        this.uuid = localStorageTracker;
      }
    }
  }

  reset() {
    this.uuid = UUID.generate();
    this.saveUUIDToStorage();
  }

  saveUUIDToStorage() {
    let cookie = false;
    let localStorage = false;

    if (this.browser.features.cookies) {
      cookie = Cookie.set(
        this.browser,
        this.storageKey,
        this.uuid,
        this.expiry
      );
    }

    if (
      this.storageType != Enums.TRACKER_STORAGE_TYPE.cookieOnly &&
      this.browser.features.localStorage
    ) {
      LocalStorage.set(this.browser, this.storageKey, this.uuid);
      localStorage = true;
    }

    return {
      cookie: cookie,
      localStorage: localStorage,
    };
  }
}
