import DOM from "./dom";
import Enums from "./enums";
import Mirror from "./mirror";
import Sequence from "./sequence";

export default class Recorder {
  constructor(browser, nodeIdSquence) {
    this.browser = browser;
    this.mirror = new Mirror(browser.document, nodeIdSquence);

    this.idSequence = new Sequence();
    this.sqSequence = new Sequence();

    this.mutationQueue = [];
    this.postponedMutations = [];

    this.recordDOMMutations();
  }

  buildFlattenedMutation(type, node, attribute) {
    return {
      id: this.idSequence.getNextValue(),
      type: type,
      node: node,
      attribute: attribute,
      ancestors: DOM.getNodeAncestors(node),
    };
  }

  deletePostponedMutation(id) {
    let index = this.postponedMutations.findIndex(
      (mutation) => mutation.id == id
    );
    this.postponedMutations.splice(index, 1);
  }

  // DEFER: test how it works when new pageview is triggered
  disconnect() {
    this.mutationObserver.disconnect();
  }

  flattenMutations(mutations) {
    let list = [];

    mutations.forEach((mutation) => {
      switch (mutation.type) {
        case "attributes":
          let flattenedAttributeMutation = this.buildFlattenedMutation(
            Enums.MUTATION_TYPE.attribute,
            mutation.target,
            mutation.attributeName
          );

          list.push(flattenedAttributeMutation);

          break;

        case "characterData":
          let flattenedTextMutation = this.buildFlattenedMutation(
            Enums.MUTATION_TYPE.text,
            mutation.target,
            null
          );

          list.push(flattenedTextMutation);

          break;

        case "childList":
          mutation.addedNodes.forEach((node) => {
            let flattenedMutation = this.buildFlattenedMutation(
              Enums.MUTATION_TYPE.addNode,
              node,
              null
            );
            list.push(flattenedMutation);
          });

          mutation.removedNodes.forEach((node) => {
            let flattenedMutation = this.buildFlattenedMutation(
              Enums.MUTATION_TYPE.removeNode,
              node,
              null
            );
            list.push(flattenedMutation);
          });

          break;
      }
    });

    return list;
  }

  static isAddNodeMutation(mutation) {
    return mutation.type == Enums.MUTATION_TYPE.addNode;
  }

  static isAddNodeOrRemoveNodeMutation(mutation) {
    return (
      mutation.type == Enums.MUTATION_TYPE.addNode ||
      mutation.type == Enums.MUTATION_TYPE.removeNode
    );
  }

  static isAttributesMutation(mutation) {
    return mutation.type == Enums.MUTATION_TYPE.attributes;
  }

  static isRemoveNodeMutation(mutation) {
    return mutation.type == Enums.MUTATION_TYPE.removeNode;
  }

  mutationsCallback(mutations) {
    this.processMutations(mutations);

    let that = this;

    this.mutationQueue.slice().forEach((mutation) => {
      let event = new CustomEvent("segmetric:mutation", {
        detail: mutation,
      });
      that.browser.document.dispatchEvent(event);
      that.mutationQueue.shift();
    });
  }

  processMutation(mutation, isPostponed = false) {
    let nodeIndexPath = this.mirror.resolveNodePath(mutation.node);

    if (nodeIndexPath) {
      let record = {
        type: mutation.type,
        nodeIndexPath: nodeIndexPath,
        sequence: this.sqSequence.getNextValue(),
      };

      if (mutation.type != Enums.MUTATION_TYPE.addNode) {
        record.nodeIdPath = this.mirror.getNodeIdPathByNodeIndexPath(
          nodeIndexPath,
          mutation.node.__segmetricId__
        );
      }

      switch (mutation.type) {
        case Enums.MUTATION_TYPE.addNode:
          record.serializedNode = this.mirror.serializeNode(mutation.node);
          this.mirror.addNode(mutation.node, nodeIndexPath);

          // getNodeIdPathByNodeIndexPath must be called after addNode (otherwise it doesn't have __segmetricId__)
          record.nodeIdPath = this.mirror.getNodeIdPathByNodeIndexPath(
            nodeIndexPath,
            mutation.node.__segmetricId__
          );

          break;

        case Enums.MUTATION_TYPE.attribute:
          let key = mutation.attribute;
          let value = mutation.node.hasAttribute(key)
            ? mutation.node.getAttribute(key)
            : null;

          record.attributeKey = key;
          record.attributeValue = value;

          this.mirror.updateAttribute(nodeIndexPath, key, value);

          break;

        case Enums.MUTATION_TYPE.removeNode:
          this.mirror.removeNode(mutation.node);
          break;

        case Enums.MUTATION_TYPE.text:
          record.text = mutation.node.textContent;
          this.mirror.updateText(nodeIndexPath, record.text);
          break;
      }

      this.mutationQueue.push(record);

      if (isPostponed) {
        this.deletePostponedMutation(mutation.id);
      }
    } else if (!isPostponed) {
      this.postponedMutations.push(mutation);
    }
  }

  processMutations(mutations) {
    let purgedMutations = Recorder.purgeMutations(
      this.flattenMutations(mutations)
    );

    purgedMutations.forEach((mutation) => {
      this.processPostponedMutations();
      this.processMutation(mutation);
    });

    this.processPostponedMutations();
  }

  processPostponedMutations() {
    let count = this.postponedMutations.length;

    this.postponedMutations
      .slice()
      .forEach((mutation) => this.processMutation(mutation, true));

    if (
      this.postponedMutations.length > 0 &&
      this.postponedMutations.length < count
    ) {
      this.processPostponedMutations();
    }
  }

  static purgeMutations(mutations) {
    let leftMutations = [];

    for (let i = 0; i < mutations.length; ++i) {
      let redundant = false;

      for (let j = 0; j < mutations.length; ++j) {
        if (i == j) {
          continue;
        }

        // case 1, purge mutation having ancestor which is added or removed
        if (Recorder.isAddNodeOrRemoveNodeMutation(mutations[j])) {
          if (mutations[i].ancestors.includes(mutations[j].node)) {
            redundant = true;
            break;
          }
        }

        // case 2, purge mutation on node which is added by another mutation
        if (
          mutations[i].node == mutations[j].node &&
          Recorder.isAddNodeMutation(mutations[j])
        ) {
          redundant = true;
          break;
        }

        // case 3, purge mutation on node which is removed by another mutation
        if (
          mutations[i].node == mutations[j].node &&
          Recorder.isRemoveNodeMutation(mutations[j])
        ) {
          redundant = true;
          break;
        }

        // case 4, purge duplicate mutation
        if (
          i > j &&
          mutations[i].type == mutations[j].type &&
          mutations[i].node == mutations[j].node
        ) {
          redundant = true;
          break;
        }
      }

      // case 5, purge mutation on script node descendants
      if (
        mutations[i].ancestors.some((ancestor) => DOM.isTag(ancestor, "script"))
      ) {
        redundant = true;
      }

      // case 6, purge attributes mutation on script nodes
      if (
        DOM.isTag(mutations[i].node, "script") &&
        Recorder.isAttributesMutation(mutations[i])
      ) {
        redundant = true;
      }

      if (!redundant) {
        leftMutations.push(mutations[i]);
      }
    }

    return leftMutations;
  }

  recordDOMMutations() {
    this.mutationObserver = new MutationObserver((mutations) => {
      this.mutationsCallback(mutations);
    });

    this.mutationObserver.observe(this.browser.document, {
      attributes: true,
      attributeOldValue: false,
      characterData: true,
      characterDataOldValue: false,
      childList: true,
      subtree: true,
    });
  }
}
