export default class LocalStorage {
  static delete(browser, key) {
    browser.window["localStorage"].removeItem(key);
  }

  static get(browser, key) {
    return browser.window["localStorage"].getItem(key);
  }

  static set(browser, key, value) {
    browser.window["localStorage"].setItem(key, value);
  }
}
