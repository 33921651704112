import { v4 as uuidv4 } from "uuid";

export default class UUID {
  static get REGEX() {
    return /^[0-9a-f]{8}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{12}$/;
  }

  static generate() {
    return uuidv4();
  }

  static validate(uuid) {
    return UUID.REGEX.test(uuid);
  }
}
