import Config from "./config";
import Enums from "./enums";
import Tracker from "./tracker";

export default class Visitor {
  constructor(browser) {
    this.tracker = new Tracker(
      browser,
      Config.VISITOR_TRACKER_STORAGE_KEY,
      Enums.TRACKER_STORAGE_TYPE.cookieAndLocalStorage,
      Config.VISITOR_EXPIRY
    );

    this.tracker.loadUUIDFromStorage();

    if (!this.tracker.uuid) {
      this.tracker.reset();
    }
  }

  getId() {
    return this.tracker.uuid;
  }

  regenerate() {
    this.tracker.saveUUIDToStorage();
  }
}
