import Utils from "../shared/utils";

export default class Cookie {
  static delete(browser, name) {
    Cookie.set(browser, name, null, -1000);
  }

  // based on StackOverflow answer: https://stackoverflow.com/a/15724300
  static get(browser, name) {
    let value = "; " + browser.document.cookie;
    let parts = value.split("; " + name + "=");
    return parts.length == 2 ? parts.pop().split(";").shift() : null;
  }

  // based on: https://www.w3schools.com/js/js_cookies.asp
  static set(browser, name, value, expiry) {
    let date = new Date(Utils.timestamp("millisecond"));
    date.setTime(date.getTime() + expiry);

    let cookie =
      name + "=" + value + ";" + "expires=" + date.toUTCString() + ";path=/";
    browser.document.cookie = cookie;

    return cookie;
  }
}
