import Utils from "../shared/utils";
import DOM from "./dom";

export default class Mirror {
  constructor(document, idSequence) {
    this.idSequence = idSequence;
    this.serializedDocument = this.serializeNode(document);
  }

  addNode(node, path) {
    let pathCopy = path.slice();
    let nodeIndex = pathCopy.pop();

    let serializedNode = this.serializeNode(node);

    let parentNode = this.getNodeByPath(pathCopy);
    parentNode.ch.splice(nodeIndex, 0, serializedNode);

    return serializedNode;
  }

  getNodeByPath(path) {
    return DOM.getNodeByPath(path, this.serializedDocument, "ch");
  }

  getNodeIdPathByNodeIndexPath(
    indexPath,
    lastNodeId,
    level = 0,
    root = this.serializedDocument
  ) {
    let result = [];

    if (level == 0) {
      result.push(root.id);
    }

    if (indexPath.length > 0) {
      let index = indexPath[0];

      if (root.ch && root.ch.length > index) {
        let node = root.ch[index];
        let [, ...newIndexPath] = indexPath;
        result.push(node.id);

        let recurseResult = this.getNodeIdPathByNodeIndexPath(
          newIndexPath,
          lastNodeId,
          level + 1,
          node
        );
        result.push(...recurseResult);
      } else {
        result.push(lastNodeId);
      }
    }

    return result;
  }

  getNodeIndexPathById(id, root = this.serializedDocument, acc = []) {
    if (root.id == id) {
      return acc;
    } else if (root.ch != null) {
      for (let i = 0; i < root.ch.length; ++i) {
        let path = this.getNodeIndexPathById(id, root.ch[i], acc.concat([i]));
        if (path != null) {
          return path;
        }
      }
    }

    return null;
  }

  removeNode(node) {
    let path = this.getNodeIndexPathById(node.__segmetricId__);
    let index = path.pop();
    let parentNode = this.getNodeByPath(path);
    parentNode.ch.splice(index, 1);

    DOM.unsetSubtreeIds(node);
  }

  resolveNodePath(node) {
    // CASE 1
    if (DOM.isMirrored(node)) {
      return this.getNodeIndexPathById(node.__segmetricId__);

      // CASE 2
    } else if (DOM.isMirrored(node.previousSibling)) {
      let path = this.getNodeIndexPathById(
        node.previousSibling.__segmetricId__
      );
      ++path[path.length - 1];
      return path;

      // CASE 3
    } else if (DOM.isMirrored(node.nextSibling)) {
      return this.getNodeIndexPathById(node.nextSibling.__segmetricId__);

      // CASE 4
    } else if (
      DOM.isMirrored(node.parentNode) &&
      node.previousSibling == null
    ) {
      let path = this.getNodeIndexPathById(node.parentNode.__segmetricId__);
      path.push(0);
      return path;

      // CASE 5
    } else if (DOM.isMirrored(node.parentNode) && node.nextSibling == null) {
      let path = this.getNodeIndexPathById(node.parentNode.__segmetricId__);
      let serializedParentNode = this.getNodeByPath(path);
      path.push(serializedParentNode.ch.length);

      return path;
    }

    // CASE 6
    return null;
  }

  // tested implicitely in Mirror.serializeNode() tests
  serializeChildNodes(node) {
    return [...node.childNodes].map((childNode) =>
      this.serializeNode(childNode)
    );
  }

  serializeNode(node) {
    if (node.__segmetricId__ == null) {
      node.__segmetricId__ = this.idSequence.getNextValue();
    }

    let type = DOM.getNodeType(node);
    let tag = DOM.getTag(node);

    let serializedNode = {
      id: node.__segmetricId__,
      nt: type,
    };

    switch (type) {
      case Node.COMMENT_NODE:
        return serializedNode;

      case Node.DOCUMENT_NODE:
        serializedNode["ch"] = this.serializeChildNodes(node);
        return serializedNode;

      case Node.DOCUMENT_TYPE_NODE:
        serializedNode["nm"] = node.name;

        if (!Utils.isBlank(node.publicId)) {
          serializedNode["pi"] = node.publicId;
        }

        if (!Utils.isBlank(node.systemId)) {
          serializedNode["si"] = node.systemId;
        }

        return serializedNode;

      case Node.ELEMENT_NODE:
        switch (tag) {
          case "script":
            return {
              ...serializedNode,
              tg: "noscript",
              at: {},
              ch: [],
            };

          default:
            return {
              ...serializedNode,
              tg: tag,
              at: DOM.getAttributes(node),
              ch: this.serializeChildNodes(node),
            };
        }

      case Node.TEXT_NODE:
        return {
          ...serializedNode,
          tx: node.textContent,
        };

      // not used anymore in HTML; not possible to test
      // see: https://en.wikipedia.org/wiki/CDATA (Use of CDATA in program output)
      case Node.CDATA_SECTION_NODE:
        return {
          ...serializedNode,
        };

      // not needed
      // case Node.DOCUMENT_FRAGMENT_NODE

      // XML only
      // case Node.PROCESSING_INSTRUCTION_NODE:
    }
  }

  updateAttribute(path, key, value) {
    let node = this.getNodeByPath(path);

    if (value !== null) {
      node.at[key] = value;
    } else {
      delete node.at[key];
    }
  }

  updateText(path, text) {
    let node = this.getNodeByPath(path);
    node.tx = text;
  }
}
