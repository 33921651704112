export default class Utils {
  static capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static diffStr(oldValue, newValue, isSensitive = false) {
    let oldLen = oldValue.length;
    let newLen = newValue.length;
    let diff;

    if (newValue == oldValue) {
      return Utils.STRING_DIFF_TYPE.noChange;
    } else if (newValue == "") {
      return Utils.STRING_DIFF_TYPE.clear;
    } else if (newValue.startsWith(oldValue)) {
      if (!isSensitive) {
        diff = newValue.slice(oldLen);
        return [Utils.STRING_DIFF_TYPE.append, diff];
      } else {
        return Utils.STRING_DIFF_TYPE.append;
      }
    } else if (newValue.endsWith(oldValue)) {
      if (!isSensitive) {
        diff = newValue.slice(0, newLen - oldLen);
        return [Utils.STRING_DIFF_TYPE.prepend, diff];
      } else {
        return Utils.STRING_DIFF_TYPE.prepend;
      }
    } else if (oldValue.endsWith(newValue)) {
      return [Utils.STRING_DIFF_TYPE.unPrepend, oldLen - newLen];
    } else if (oldValue.startsWith(newValue)) {
      return [Utils.STRING_DIFF_TYPE.unAppend, oldLen - newLen];
    } else {
      if (!isSensitive) {
        return [Utils.STRING_DIFF_TYPE.replace, newValue];
      } else {
        return [Utils.STRING_DIFF_TYPE.replace, newValue.length];
      }
    }
  }

  static isBlank(value) {
    if (
      value === null ||
      value === false ||
      (Array.isArray(value) && value.length == 0) ||
      (typeof value === "string" && value.trim() === "") ||
      (typeof value === "object" && Object.keys(value).length == 0) ||
      typeof value === "undefined"
    ) {
      return true;
    }

    return false;
  }

  static isPresent(value) {
    return !Utils.isBlank(value);
  }

  static last(arr) {
    return arr.length > 0 ? arr[arr.length - 1] : null;
  }

  static get STRING_DIFF_TYPE() {
    return {
      noChange: 0,
      append: 1,
      unAppend: 2,
      prepend: 3,
      unPrepend: 4,
      replace: 5,
      clear: 6,
    };
  }

  static timestamp(unit = "second") {
    let timestamp = Date.now();

    switch (unit) {
      case "second":
        return Math.round(timestamp / 1000.0);

      case "millisecond":
        return timestamp;

      default:
        throw "Unit not supported: " + unit;
    }
  }

  static trimLeft(str, char = null) {
    if (char) {
      let count = 0;

      for (var i = 0; i < str.length; i++) {
        if (str.charAt(i) == char) {
          ++count;
        } else {
          break;
        }
      }

      return str.substring(count);
    } else {
      return str.trimLeft();
    }
  }
}

// DEFER: test
function onReady(document, callback) {
  if (
    document.readyState === "interactive" ||
    document.readyState === "complete"
  ) {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", function listener() {
      document.removeEventListener("DOMContentLoaded", listener);
      callback();
    });
  }
}

export { onReady };
