export default class Config {
  static get CHANNEL_TOPIC_PREFIX() {
    return "vr:";
  }

  // 1 second (milliseconds)
  static get HEARTBEAT_INTERVAL() {
    return 1000;
  }

  // the list is based on: https://docs.heap.io/docs/events#section-attributes
  // these attributes are only ignored for events (e.g. click event), session recording doesn't ignore any attributes
  static get IGNORED_ATTRIBUTES() {
    return [
      "data-ember-action",
      "data-phx-session",
      "data-phx-static",
      "data-react-checksum",
      "data-reactid",
      "maxlength",
      "onclick",
      "onsubmit",
      "style",
    ];
  }

  // 30 minutes (milliseconds)
  static get SESSION_EXPIRY() {
    return 30 * 60 * 1000;
  }

  static get SESSION_TRACKER_STORAGE_KEY() {
    return "segmetric_ss";
  }

  // 1 second (milliseconds)
  static get TEST_SESSION_EXPIRY() {
    return 1000;
  }

  // 2 years (milliseconds)
  static get VISITOR_EXPIRY() {
    return 2 * 365 * 24 * 60 * 60 * 1000;
  }

  static get VISITOR_TRACKER_STORAGE_KEY() {
    return "segmetric_vr";
  }
}
