import Config from "./config";
import Cookie from "./cookie";
import DOM from "./dom";
import Enums from "./enums";
import Utils from "../shared/utils";
import UUID from "../shared/uuid";

export default class Builder {
  // actually focusout event (because it bubbles), not blur event
  static buildBlurEvent(blurEvent, pageviewId, sessionId) {
    return Builder.buildDOMEvent(Enums.EVENT_TYPE.blur, blurEvent, pageviewId, sessionId);
  }

  static buildChangeEvent(changeEvent, pageviewId, sessionId) {
    let target = changeEvent.target;

    let event = {
      dp: Builder.buildDOMPathFromEvent(changeEvent),
      et: Enums.EVENT_TYPE.change,
      id: UUID.generate(),
      ni: Builder.buildNodeIdPath(target),
      nx: Builder.buildNodeIndexPath(target),
      pv: pageviewId,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };

    if (DOM.isSelect(target)) {
      event["cx"] = target.selectedIndex;
    } else if (DOM.isCheckbox(target)) {
      event["cx"] = target.checked ? 1 : 0;
    }

    if (DOM.isPassword(target)) {
      event["sv"] = true;
    } else {
      if (target.value != "") {
        event["vl"] = target.value;
      }
    }

    return event;
  }

  static buildClickEvent(clickEvent, pageviewId, sessionId) {
    let event = Builder.buildDOMEvent(
      Enums.EVENT_TYPE.click,
      clickEvent,
      pageviewId,
      sessionId
    );

    event["mx"] = clickEvent.pageX;
    event["my"] = clickEvent.pageY;

    return event;
  }

  // DEFER: test
  static buildDOMEvent(type, domEvent, pageviewId, sessionId) {
    let event = {
      dp: Builder.buildDOMPathFromEvent(domEvent),
      et: type,
      id: UUID.generate(),
      ni: Builder.buildNodeIdPath(domEvent.target),
      nx: Builder.buildNodeIndexPath(domEvent.target),
      pv: pageviewId,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };

    return event;
  }

  static buildDOMPathFromEvent(event) {
    return DOM.buildDOMPathFromNodes(event.composedPath())
  }

  static buildErrorEvent(errorEvent, pageviewId, sessionId) {
    return {
      cn: errorEvent.colno,
      et: Enums.EVENT_TYPE.error,
      fl: errorEvent.filename,
      id: UUID.generate(),
      ln: errorEvent.lineno,
      ms: errorEvent.message,
      pv: pageviewId,
      ss: sessionId,
      st: errorEvent.error.stack,
      ts: Utils.timestamp("millisecond"),
    };
  }

  // actually focusin event (because it bubbles), not focus event
  static buildFocusEvent(focusEvent, pageviewId, sessionId) {
    return Builder.buildDOMEvent(
      Enums.EVENT_TYPE.focus,
      focusEvent,
      pageviewId,
      sessionId
    );
  }

  static buildHoverEvent(pointerEvent, type, pageviewId, sessionId) {
    let event = Builder.buildDOMEvent(
      Enums.EVENT_TYPE.hover,
      pointerEvent,
      pageviewId,
      sessionId
    );

    event["pi"] = pointerEvent.pointerId
    event["pt"] = pointerEvent.pointerType

    event["px"] = Math.round(pointerEvent.pageX);
    event["py"] = Math.round(pointerEvent.pageY);

    event["tp"] = type

    if (pointerEvent.relatedTarget) {
      event["rp"] = DOM.buildDOMPathFromNode(pointerEvent.relatedTarget)
      event["ri"] = Builder.buildNodeIdPath(pointerEvent.relatedTarget)
      event["rx"] = Builder.buildNodeIndexPath(pointerEvent.relatedTarget)
    }

    return event;
  }

  static buildIdentifyEvent(identifier, pageviewId, sessionId, metadata = null) {
    const event = {
      et: Enums.EVENT_TYPE.identify,
      id: UUID.generate(),
      it: identifier,
      pv: pageviewId,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };

    if (metadata) {
      event["md"] = metadata
    }

    return event
  }

  static buildInputEvent(inputBuffer, pageviewId, sessionId) {
    return {
      et: Enums.EVENT_TYPE.input,
      id: UUID.generate(),
      in: inputBuffer,
      pv: pageviewId,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };
  }

  static buildInputBufferItem(event, sequence) {
    let elem = event.target;

    let isSensitive = DOM.isPassword(elem);
    let diff = Utils.diffStr(
      elem.__segmetricOldValue__,
      elem.value,
      isSensitive
    );

    let item = {
      df: diff,
      dp: Builder.buildDOMPathFromEvent(event),
      ni: Builder.buildNodeIdPath(elem),
      nx: Builder.buildNodeIndexPath(elem),
      sq: sequence.getNextValue(),
      ts: Utils.timestamp("millisecond"),
    };

    if (isSensitive) {
      item["sv"] = true;
    }

    return item;
  }

  static buildMouseMoveEvent(mousePositionBuffer, pageviewId, sessionId) {
    return {
      et: Enums.EVENT_TYPE.mouseMove,
      id: UUID.generate(),
      mp: mousePositionBuffer,
      pv: pageviewId,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };
  }

  static buildMutationEvent(browser, mutation, sessionId, pageviewId) {
    let event = {
      et: Enums.EVENT_TYPE.mutation,
      id: UUID.generate(),
      mt: mutation.type,
      ni: mutation.nodeIdPath,
      nx: mutation.nodeIndexPath,
      pv: pageviewId,
      sq: mutation.sequence,
      ss: sessionId, // needed for detecting assets (see mutation consumer)
      ts: Utils.timestamp("millisecond"),
      ur: browser.window.location.href, // needed for detecting assets (see mutation consumer)
    };

    switch (mutation.type) {
      case Enums.MUTATION_TYPE.addNode:
        event["dt"] = Builder.normalizeSerializedNodePayload(
          mutation.serializedNode
        );
        break;

      case Enums.MUTATION_TYPE.removeNode:
        break;

      case Enums.MUTATION_TYPE.attribute:
        event["dt"] = {
          ak: mutation.attributeKey,
          av: mutation.attributeValue,
        };
        break;

      case Enums.MUTATION_TYPE.text:
        event["dt"] = { tx: mutation.text };
        break;
    }

    return event;
  }

  static buildNodeIdPath(node) {
    let ancestors = DOM.getNodeAncestors(node);
    let nodes = [node].concat(ancestors).reverse();

    return nodes.map((n) => n.__segmetricId__);
  }

  static buildNodeIndexPath(node) {
    let path = [];
    let parentNode = node.parentNode;

    while (parentNode != null) {
      let childNodes = Array.from(parentNode.childNodes);
      path.push(childNodes.indexOf(node));
      node = parentNode;
      parentNode = parentNode.parentNode;
    }

    return path.reverse();
  }

  static buildOrientationChangeEvent(orientationChangeEvent, pageviewId, sessionId) {
    return {
      et: Enums.EVENT_TYPE.orientationChange,
      id: UUID.generate(),
      oa: orientationChangeEvent.target.screen.orientation.angle,
      ot: orientationChangeEvent.target.screen.orientation.type,
      pv: pageviewId,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };
  }

  static buildPageviewEvent(browser, sessionId, prevPageviewEvent) {
    let doc = browser.document;
    let orientation = browser.screen.orientation;

    let event = {
      ch: doc.documentElement.clientHeight,
      cw: doc.documentElement.clientWidth,
      et: Enums.EVENT_TYPE.pageview,
      id: UUID.generate(),
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
      ur: browser.window.location.href,
    };

    if (orientation && !Utils.isBlank(orientation.angle)) {
      event["oa"] = orientation.angle;
    }

    if (orientation && !Utils.isBlank(orientation.type)) {
      event["ot"] = orientation.type;
    }

    // see: https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigation/type
    if (
      !prevPageviewEvent &&
      browser.performance.navigation.type ==
        PerformanceNavigation.TYPE_NAVIGATE &&
      !Utils.isBlank(doc.referrer)
    ) {
      event["rf"] = doc.referrer;
    }

    if (!Utils.isBlank(doc.title)) {
      event["tl"] = doc.title;
    }

    let linkEventId = Cookie.get(browser, Config.LINK_EVENT_KEY);
    if (linkEventId) {
      Cookie.delete(browser, Config.LINK_EVENT_KEY);
      event["le"] = linkEventId;
    }

    return event;
  }

  static buildPageviewTitleCorrectionEvent(browser, pageviewEvent, sessionId) {
    return {
      et: Enums.EVENT_TYPE.pageviewTitleCorrection,
      id: UUID.generate(),
      nt: browser.document.title,
      ot: pageviewEvent.tl,
      pv: pageviewEvent.id,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };
  }

  static buildPointerPressEvent(pointerPressEvent, type, pageviewId, sessionId) {
    let event = Builder.buildDOMEvent(
      Enums.EVENT_TYPE.pointerPress,
      pointerPressEvent,
      pageviewId,
      sessionId
    );

    event["pi"] = pointerPressEvent.pointerId;
    event["pt"] = pointerPressEvent.pointerType;
    event["px"] = Math.round(pointerPressEvent.pageX);
    event["py"] = Math.round(pointerPressEvent.pageY);
    event["tp"] = type;

    if (pointerPressEvent.pointerType === "mouse") {
      Builder.setMousePointerFields(event, pointerPressEvent)
    } else {
      Builder.setTouchPointerFields(event, pointerPressEvent)
    }

    return event;
  }

  static buildResizeEvent(clientSizeBuffer, pageviewId, sessionId) {
    return {
      cs: clientSizeBuffer,
      et: Enums.EVENT_TYPE.resize,
      id: UUID.generate(),
      pv: pageviewId,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };
  }

  static buildScrollEvent(scrollPositionBuffer, pageviewId, sessionId) {
    return {
      et: Enums.EVENT_TYPE.scroll,
      id: UUID.generate(),
      pv: pageviewId,
      sp: scrollPositionBuffer,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };
  }

  static buildSelectEvent(selectEvent, pageviewId, sessionId) {
    let target = selectEvent.target;
    let selectedText = target.value.substring(
      target.selectionStart,
      target.selectionEnd
    );

    return {
      dp: Builder.buildDOMPathFromEvent(selectEvent),
      et: Enums.EVENT_TYPE.select,
      id: UUID.generate(),
      pv: pageviewId,
      ss: sessionId,
      ft: target.value,
      ni: Builder.buildNodeIdPath(target),
      nx: Builder.buildNodeIndexPath(target),
      st: selectedText,
      sb: target.selectionStart,
      se: target.selectionEnd,
      ts: Utils.timestamp("millisecond"),
    };
  }

  static buildSessionEvent(browser, sessionId, expiredSessionId) {
    let features = browser.features;

    let event = {
      cd: features.colorDepth,
      co: features.cookies,
      et: Enums.EVENT_TYPE.session,
      fl: features.flash,
      id: sessionId,
      jv: features.java,
      ll: features.localeList,
      ls: features.localStorage,
      pr: features.pixelRatio,
      sh: features.screenHeight,
      sw: features.screenWidth,
      ts: Utils.timestamp("millisecond"),
      ua: features.userAgent,
    };

    if (expiredSessionId) {
      event["es"] = expiredSessionId;
    }

    if (!Utils.isBlank(features.flashVersion)) {
      event["fv"] = features.flashVersion;
    }

    return event;
  }

  static buildSnapshotEvent(
    browser,
    recorder,
    sessionId,
    pageviewId,
    lastPosition
  ) {
    let event = {
      dc: Builder.normalizeSerializedNodePayload(
        recorder.mirror.serializedDocument
      ),
      et: Enums.EVENT_TYPE.snapshot,
      id: UUID.generate(),
      pv: pageviewId,
      ss: sessionId, // needed for detecting assets (see snapshot consumer)
      sx: Math.round(browser.window.scrollX),
      sy: Math.round(browser.window.scrollY),
      ts: Utils.timestamp("millisecond"),
      ur: browser.window.location.href, // needed for detecting assets (see snapshot consumer)
    };

    if (lastPosition[1]) {
      event["mx"] = lastPosition[1];
    }

    if (lastPosition[2]) {
      event["my"] = lastPosition[2];
    }

    return event;
  }

  // DEFER: test
  static buildSubmitEvent(submitEvent, pageviewId, sessionId) {
    const formFieldNodes = submitEvent
      .target
      .querySelectorAll("input, textarea, select")

    const formFields = Array.from(formFieldNodes)
      .map(field =>
        Builder._buildSubmitEventFormField(field)
      )

    return {
      dp: Builder.buildDOMPathFromEvent(submitEvent),
      et: Enums.EVENT_TYPE.submit,
      ff: formFields,
      id: UUID.generate(),
      ni: Builder.buildNodeIdPath(submitEvent.target),
      nx: Builder.buildNodeIndexPath(submitEvent.target),
      pv: pageviewId,
      ss: sessionId,
      ts: Utils.timestamp("millisecond"),
    };
  }

  // DEFER: test
  static _buildSubmitEventFormField(field) {
    const value = field.value === "" ? null : field.value;

    const payload = [
      DOM.buildDOMPathFromNode(field),
      Builder.buildNodeIdPath(field),
      Builder.buildNodeIndexPath(field),
      value
    ]

    if (DOM.isCheckbox(field) || DOM.isRadio(field)) {
      if (field.checked) {
        payload.push(true);
      }
    }

    return payload;
  }

  static buildTouchMoveEvent(touchPositionBuffer, pageviewId, sessionId) {
    return {
      et: Enums.EVENT_TYPE.touchMove,
      id: UUID.generate(),
      pv: pageviewId,
      ss: sessionId,
      tp: touchPositionBuffer,
      ts: Utils.timestamp("millisecond"),
    };
  }

  static buildVisibilityChangeEvent(browser, pageviewId, sessionId, trigger) {
    let state;

    if (trigger == "visibilityChange") {
      state = Enums.VISIBILITY_CHANGE_STATE[browser.document.visibilityState];
    } else {
      state = Enums.VISIBILITY_CHANGE_STATE.pageHide;
    }

    return {
      et: Enums.EVENT_TYPE.visibilityChange,
      id: UUID.generate(),
      pv: pageviewId,
      ss: sessionId,
      st: state,
      ts: Utils.timestamp("millisecond"),
    };
  }

  static normalizeSerializedNodePayload(payload) {
    let normalizedPayload = {};

    for (let [key, value] of Object.entries(payload)) {
      switch (key) {
        case "at": // attributes
          if (Object.keys(value).length > 0) {
            normalizedPayload.at = value;
          }
          break;

        case "ch": // child nodes
          if (value.length > 0) {
            normalizedPayload.ch = value.map((item) =>
              Builder.normalizeSerializedNodePayload(item)
            );
          }
          break;

        default:
          normalizedPayload[key] = value;
          break;
      }
    }

    return normalizedPayload;
  }

  static setMousePointerFields(payload, pointerPressEvent) {
    payload["bt"] = pointerPressEvent.button;
    payload["ka"] = pointerPressEvent.altKey;
    payload["kc"] = pointerPressEvent.ctrlKey;
    payload["km"] = pointerPressEvent.metaKey;
    payload["ks"] = pointerPressEvent.shiftKey;
  }

  // DEFER: test
  static setTouchPointerFields(payload, pointerPressEvent) {
    payload["pr"] = pointerPressEvent.pressure
    payload["wd"] = Math.round(pointerPressEvent.width)
    payload["ht"] = Math.round(pointerPressEvent.height)
  }
}
