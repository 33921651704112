export default class Browser {
  constructor(window) {
    this.window = window;
    this.features = this.detectFeatures();
  }

  detectFeatures() {
    let flashPlugin = this.getFlashPlugin();
    let flashEnabled = this.isFlashEnabled(flashPlugin);

    return {
      beacon: this.isBeaconEnabled(),
      colorDepth: this.screen.colorDepth,
      cookies: this.isCookiesEnabled(),
      flash: flashEnabled,
      flashVersion: this.getFlashVersion(flashEnabled, flashPlugin),
      java: this.navigator.javaEnabled(),
      localeList: this.navigator.languages,
      localStorage: this.isLocalStorageEnabled(),
      pixelRatio: this.window.devicePixelRatio,
      screenWidth: this.screen.width,
      screenHeight: this.screen.height,
      userAgent: this.navigator.userAgent,
    };
  }

  get document() {
    return this.window.document;
  }

  get history() {
    return this.window.history;
  }

  get navigator() {
    return this.window.navigator;
  }

  get performance() {
    return this.window.performance;
  }

  get screen() {
    return this.window.screen;
  }

  // based on: https://developer.mozilla.org/en-US/docs/Web/API/NavigatorPlugins/plugins
  getFlashPlugin() {
    return this.navigator.plugins.namedItem("Shockwave Flash");
  }

  // based on: https://developer.mozilla.org/en-US/docs/Web/API/NavigatorPlugins/plugins
  getFlashVersion(flashEnabled, flashPlugin) {
    if (flashEnabled) {
      if (flashPlugin.version) {
        return flashPlugin.version;
      } else {
        return flashPlugin.description.replace(/Shockwave Flash /, "");
      }
    } else {
      return null;
    }
  }

  isBeaconEnabled() {
    return !!this.navigator.sendBeacon;
  }

  // based on: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
  // related StackOverflow answer: https://stackoverflow.com/a/48521179
  isCookiesEnabled() {
    let document = this.document;

    try {
      document.cookie = "cookies_test=1";
      let result = document.cookie.indexOf("cookies_test=") !== -1;
      document.cookie = "cookies_test=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
      return result;
    } catch (e) {
      return false;
    }
  }

  // based on: https://developer.mozilla.org/en-US/docs/Web/API/NavigatorPlugins/plugins
  isFlashEnabled(flashPlugin) {
    if (flashPlugin) {
      return true;
    } else {
      return false;
    }
  }

  // based on: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
  // related StackOverflow answer: https://stackoverflow.com/a/52671129
  isLocalStorageEnabled() {
    try {
      let storage = this.window["localStorage"];
      let key = "local_storage_test";
      storage.setItem(key, "1");
      storage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }
}
